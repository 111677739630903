import React, { Component, useEffect, useState } from "react";
import { useMutation, useQuery, useLazyQuery, useSubscription } from '@apollo/client';

import * as toxicity from '@tensorflow-models/toxicity';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

const External = (props) => {
    let id = props.id;
    let latitude = props.latitude;
    let longitude = props.longitude;

    return (
        <Accordion slotProps={{ transition: { unmountOnExit: true } }} key={'accordion-comments-' + id} className="rounded-xl w-full my-2 mx-auto justify-self-center text-xs">
            <AccordionSummary id="panel-header" className="mx-auto text-center" aria-controls="panel-content">
                <span key={"external-" + id} className={"mx-auto"}>
                🔗 External Links - Reach out on other platforms.
                </span>
            </AccordionSummary>
            <AccordionDetails>
                External Links:
                <div className="flex mx-auto justify-center">
                    <a href={"https://www.mobilize.us/?lat=" + latitude + "&lon=" + longitude} target="_blank">
                        <img style={{ height: "50px" }} src="https://mobilizeamerica.imgix.net/uploads/organization/logo-for-mobilize-feed%403x_20200930173319016754.png?auto=format" alt="Mobilize Logo" />
                    </a>
                </div>
            </AccordionDetails>
        </Accordion>
    );
    }
export default External;

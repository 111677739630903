import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { SIGNUP } from '../utils/mutations';
import Auth from '../utils/auth';
import { lineWobble } from 'ldrs'

lineWobble.register()

const Register = (props) => {
  const [loading, setLoading] = useState(false);

  function generateRandomBase64String(length) {
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';
    var result = '';
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  }
  
  // Generate a random base 64 32 character string
  var randomBase64StringUser = generateRandomBase64String(16);
  var randomBase64StringPassword = generateRandomBase64String(16);

  const [formState, setFormState] = useState({
    username: randomBase64StringUser,
    password: randomBase64StringPassword,
  });
  const [signUp, { error, data }] = useMutation(SIGNUP);

  // update state based on form input changes
  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormState({
      ...formState,
      [name]: value,
    });
  };

  // submit form
  const handleFormSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    console.log(formState)
    console.log(event);
    try {
      const { data } = await signUp({
        variables: { ...formState },
      });
      console.log(data);
      console.log(formState);
      if (data.signUp.token !== null) {
        await Auth.login(data.signUp.token);
        props.setIsRegisterPaneOpen(false);
      }
    
    } catch (e) {
      console.log(e);
    }
  };
  // Print the random base 64 32 character string to the console
  return (
    <main className="p-5 m-5 flex flex-col">
      <div className="flex flex-col">
        <div className="flex flex-col">
          <span className="text-center text-xl" style={{fontSize: "32px", margin: "20px"}}>Register</span>
          <div className="flex flex-col">
            {loading ? (
              <div className="flex flex-col items-center justify-center">
                <l-line-wobble
                  size="800"
                  stroke="5"
                  bg-opacity="0.1"
                  speed="1.75" 
                  color="black" 
                  className="items-center justify-center" 
                ></l-line-wobble>
              </div>
            ) : (
              <form className="flex flex-col" onSubmit={handleFormSubmit}>
                <div className="flex gap-4 items-center flex-col"> 
                  <input
                    className="input-active"
                    placeholder="Username (Public)"
                    name="username"
                    type="text"
                    value={formState.username}
                    onChange={handleChange}
                  />
                <input
                  className="input-active"
                  placeholder="Password"
                  name="password"
                  type="password"
                  value={formState.password}
                  onChange={handleChange}
                />
                <p className="fs-7 text-center">Sign up with us! It only takes a few seconds and we don't ask for your email address, because we don't want it!</p>
                <p className="fs-7 text-center">Don't forget your password, you won't be able to reset it.</p>
                 <button style={{ cursor: 'pointer' }} type="submit" className="btn-active">Register</button>
                 <p className="text-center">Already have an account?</p>
                 <button style={{ cursor: 'pointer' }} onClick={() =>  { props.setIsLoginPaneOpen(true); props.setIsRegisterPaneOpen(false); }} className="btn-active">Log In</button>
              </div>
              </form>
            )}

            {error && console.log(error) && (
              <div className="text-center">
               There was an error.
              </div>
            )}
            
          </div>
        </div>
      </div>
    </main>
  );
};

export default Register;

import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { LOGIN } from '../utils/mutations';
import Auth from '../utils/auth';

function Login(props) {
  const [formState, setFormState] = useState({ username: '', password: '' });
  const [login, { error }] = useMutation(LOGIN);

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    try {
      const mutationResponse = await login({
        variables: { username: formState.username, password: formState.password },
      });
      const token = mutationResponse.data.login.token;
      Auth.login(token);
      props.setCurrentUser(mutationResponse.data.login.user._id);
      props.setIsLoginPaneOpen(false);
    } catch (e) {
      console.log(e);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormState({
      ...formState,
      [name]: value,
    });
  };

  return (
    <main className="p-5 m-5 text-center justify-contents-center flex flex-col">
      <div className="flex flex-col">
        <div className="flex flex-col">
        <span className="text-center text-xl" style={{fontSize: "32px", margin: "20px"}}>Log In</span>
          <div className="flex flex-col">
              <form className="flex flex-col" onSubmit={handleFormSubmit}>
              <div className="flex gap-4 items-center flex-col"> 
                  <input
                    className="input-active"
                    placeholder="Username"
                    name="username"
                    type="text"
                    value={formState.username}
                    onChange={handleChange}
                  />

                <input
                  className="input-active"
                  placeholder="Password"
                  name="password"
                  type="password"
                  value={formState.password}
                  onChange={handleChange}
                />
                <button style={{ cursor: 'pointer' }} type="submit" className="btn-active">Login</button>
                <p className="text-center">Don't have an account?</p>
                <button style={{ cursor: 'pointer' }} onClick={() =>  { props.setIsRegisterPaneOpen(true); props.setIsLoginPaneOpen(false); }} className="btn-active">Register</button>
                </div>
              </form>
            

              {error ? (
          <div>
            <p className="error-text">The provided credentials are incorrect.</p>
          </div>
        ) : null}
          </div>
        </div>
      </div>
    </main>
  );
}

export default Login;
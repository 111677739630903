import React, { useEffect, useState } from 'react';

function Account() {
  const [showIdmeButton, setShowIdmeButton] = useState(false);

  useEffect(() => {
    setShowIdmeButton(true);

  }, []); // Empty dependency array ensures this runs only once when the component mounts

  return (
    <main className="p-5 m-5 text-center justify-contents-center flex flex-col">
      <div className="flex flex-col">
        <div className="flex flex-col">
          <span
            className="text-center text-xl"
            style={{ fontSize: '32px', margin: '20px' }}
          >
            Account
          </span>
          <div>
          <a href="https://groups.id.me/?client_id=9c5df2988ffbe63c12afdd0535023cf2&redirect_uri=https://www.projectcivilian.com/&response_type=code&scopes=government,military,teacher">
            <img
              style={{ cursor: 'pointer'}}
              src={
                "https://s3.amazonaws.com/idme/buttons/v4/verify-with-idme-green.png"
              }
              alt="Verify with ID.me"
            />
          </a>
          <span>
          ID.me is a trusted technology partner in helping to keep your
          personal information safe. We specialize in digital identity
          protection and help partners make sure you're you—and not someone
          pretending to be you—before giving you access to your information or
          offer.
          </span>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Account;

function scaleValuesToMaxCount(values, maxCount) {
  // Find the maximum value in the original array
  const originalMax = Math.max(...values);
  
  // Scale each value in the array to the new maxCount, maintaining their relative proportions
  const scaledValues = values.map(value => (value / originalMax) * maxCount);
  
  return scaledValues;
}

export const clusterLayer = (maxCount) => {
  // Ensure maxCount is greater than 1
  const safeMaxCount = Math.max(20, maxCount);

  return {
    id: 'clusters',
    type: 'circle',
    source: 'postPoints',
    filter: ['has', 'point_count'],
    paint: {
      'circle-color': [
        'interpolate', ['exponential', 1], ['get', 'point_count'],
        1, 'rgba(0, 0, 0, .5)',
        safeMaxCount, 'rgba(150, 150, 150, 1)',
      ],
      'circle-radius': [
        'interpolate', ['exponential', 1], ['get', 'point_count'],
        1, 15,
        safeMaxCount, 50
      ],
      // Ensure all other properties follow the same pattern...
    },
  };
};
export const clusterCountLayer = (maxCount) => {
  const safeMaxCount = Math.max(20, maxCount);
  return {
  id: 'cluster-count',
  type: 'symbol',
  source: 'postPoints',
  filter: ['has', 'point_count'],
  layout: {
    'text-field': '{point_count_abbreviated}',
    'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
    'text-size': [
      'interpolate', ['exponential', 1], ['get', 'point_count'],
      1, 15,
      safeMaxCount, 50
    ],
  },
  paint: {
    'text-color': '#ffffff',
  },
  }
};

export const unclusteredPointLayer = {
  id: 'unclustered-point',
  type: 'circle',
  source: 'postPoints',
  
  filter: ['!', ['has', 'point_count']],
  paint: {
    'circle-radius': 15,
    'circle-color': 'rgba(0, 0, 0, .15)',
    'circle-stroke-width': 5,
    'circle-stroke-color': '#462b65'
  }
  // layout: {
  //   'icon-image': 'leader_line',
  //   'icon-anchor': 'bottom',
  //   'text-offset': [0, -10.5],
  // }
};

export const pointLayer = {
  id: 'projectCivilian',
  type: 'circle',
  
  paint: {
    'circle-color': 'rgba(255, 0, 0, .75)',
    'circle-radius': 5,
    'circle-stroke-width': 5,
    'circle-stroke-color': 'rgba(15, 15, 15, .2)',
  },
};

export const currentLocationLayer = {
  id: 'current-location',
  type: 'circle',
  minzoom: 14,
  paint: {
    'circle-radius': 15,
    'circle-color': 'rgba(0, 0, 0, .95)',
    'circle-stroke-width': 5,
    'circle-stroke-color': 'rgba(0, 0, 0, .95)'
  }
};

export const currentLocationLabel = {
  id: 'current-location-label',
  type: 'symbol',
  source: 'current-location', // Make sure this source points to the current location data
  minzoom: 14,
  layout: {
    'text-field': 'You',
    'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],

    // 'text-anchor': 'left',
    // 'text-offset': [0.5, 0], // Adjust the offset as needed
    'text-size': 14
  },
  paint: {
    'text-color': '#fff'
  }
};
import React from 'react';
import { useEffect } from 'react';
import SearchArea from './SearchArea';
const Footer = (props) => {
    return (
        <footer className="footer mx-auto flex items-center">
            <div className="mx-auto">
            <SearchArea
                
                setSearch={props.setSearch}
                searchText={props.searchText}
                setSearchText={props.setSearchText}
                search={props.search}
                searchMade={props.searchMade}
                setSearchMade={props.setSearchMade}
                theme={props.theme}
                postLength={props.postLength}
                filterData={props.filterData}
                filteredData={props.filteredData}
                setFilteredData={props.setFilteredData}
            />
            </div>
            
        </footer>
    );
};

export default Footer;
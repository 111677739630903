import { gql } from '@apollo/client';

export const POST_CREATED = gql`
    subscription Subscription {
        postCreated {
            _id
            user {
                _id
                username
            }
            body
            solutions {
                 _id
                solution
                upVotes
                downVotes
            }
            issues {
                category
                issues
            }
            civicInfo
            latitude
            longitude
            createdAt
        }
    }
`;

export const POST_DELETED = gql`
    subscription Subscription {
    postDeleted {
            _id
            user {
                _id
                username
            }
            body
            latitude
            longitude
            createdAt
        }
    }
`;

export const POST_UPDATED = gql`
    subscription Subscription {
    postUpdated {
            _id
            user {
                _id
                username
            }
            body
            solutions {
                _id
                solution
                upVotes
                downVotes 
            }
            issues
            civicInfo
            latitude
            longitude
            createdAt
        }
    }
`;

export const COMMENT_ADDED = gql`
    subscription Subscription($postId: ID!) {
    commentAdded(postId: $postId) {
            _id
            userId
            postId
            body
            createdAt
        }
    }
`;
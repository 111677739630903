import { gql } from '@apollo/client';

export const SIGNUP = gql`
  mutation signup($username: String!, $password: String!) {
    signUp(username: $username, password: $password) {
      token
      user {
        _id
      }
    }
  }
`;

export const LOGIN = gql`
  mutation login($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      token
      user {
        _id
      }
    }
  }
`;

export const ADD_POST = gql`
  mutation addPost($body: String!, $latitude: Float!, $longitude: Float!, $nearby: Boolean) {
    addPost(body: $body, latitude: $latitude, longitude: $longitude, nearby: $nearby) {
      body
      latitude
      longitude
      createdAt
      nearby
    }
  }
`;

export const ADD_COMMENT = gql`
  mutation addComment($postId: ID!, $body: String!) {
    addComment(postId: $postId, body: $body) {
      postId
      body
    }
  }
`;

export const DELETE_COMMENT = gql`
  mutation deleteComment($postId: ID!, $commentId: ID!) {
    deleteComment(postId: $postId, commentId: $commentId) {
      _id
    }
  }
`;

export const DELETE_POST = gql`
  mutation deletePost($userId: ID!, $postId: ID!) {
    deletePost(userId: $userId, postId: $postId) {
      user {
          _id
      }
      _id
    }
  }
`;

export const UPDATE_POST = gql`
  mutation updatePost($userId: ID!, $postId: ID!, $body: String!) {
    updatePost(userId: $userId, postId: $postId, body: $body) {
      user {
          _id
      }
      _id
    }
  }
`;


export const UPVOTE_SOLUTION = gql`
  mutation upVoteSolution($postId: ID!, $solutionId: ID!) {
    upVoteSolution(postId: $postId, solutionId: $solutionId) {
      _id
      solution
      upVotes
      downVotes
    }
  }
`;

export const DOWNVOTE_SOLUTION = gql`
  mutation downVoteSolution($postId: ID!, $solutionId: ID!) {
    downVoteSolution(postId: $postId, solutionId: $solutionId) {
      _id
      solution
      upVotes
      downVotes
    }
  }
`;
import {useEffect, useState, useRef, memo} from 'react';
import SelectedPosts from "./SelectedPosts";
import postToGeoJSON from "../utils/postToGeoJSON";
import { GET_HISTORY } from '../utils/queries';
import { useMutation, useLazyQuery, useQuery } from '@apollo/client';
import { Drawer, Button } from '@mui/material';

const Visible = memo((props) => {
    const [historyPosts, setVisiblePosts] = useState([]);

    useEffect(() => {
        try {
            const jsonData = props.postGeoJSON.features;
            // console.log(jsonData);
            
            if (jsonData && jsonData.length > 0) {
                setVisiblePosts(jsonData);
            } else {
                console.warn("Transformed data is empty or not an array");
            }
        } catch (err) {
            console.error("Error transforming data: ", err);
        }
    }, [props.posts]);

    return (
        <>{ historyPosts.length > 0 && 
            <>
            <SelectedPosts
                update={props.update}
                setEditUser={props.setEditUser}
                setEditPostId={props.setEditPostId}
                setEditPostBody={props.setEditPostBody}
                selectedMapPosts={historyPosts}
                setSelectedMapPosts={props.selectedMapPosts}
                setIsSelectedPaneOpen={props.setIsSelectedPaneOpen}
                isSelectedPaneOpen={props.isSelectedPaneOpen}
                setIsPostPaneOpen={props.setIsPostPaneOpen}
                setIsEditMode={props.setIsEditMode}
                setIsVisiblePaneOpen={props.setIsVisiblePaneOpen}
                setViewport={props.setViewport}
                post={props.selectedMapPosts[0]}
                filterData={props.filterData}
                setSearch={props.setSearch}
                searchText={props.searchText}
                setSearchText={props.setSearchText}
                search={props.search}
                searchMade={props.searchMade}
                setSearchMade={props.setSearchMade}
                currentUser={props.currentUser}
            /> 
            </>
        }</>
    );
});

export default Visible;
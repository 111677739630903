import './App.css';
import Main from './components/Main';
import React from 'react';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { split } from '@apollo/client';

const baseUri = process.env.NODE_ENV === 'production' ? '/graphql' : 'http://localhost:4000/graphql';
const baseUriWs = process.env.NODE_ENV === 'production' ? 'wss://knowledged-c6f63efaf1e0.herokuapp.com/graphql' : 'ws://localhost:4000/graphql';

const httpLink = createHttpLink({
  // uri: `/graphql`
  uri: baseUri
});

const wsLink = new GraphQLWsLink(createClient({
  url: baseUriWs, // Changed to port 3001
}));

// const wsLink = new GraphQLWsLink(createClient({
//   url: 'ws://localhost:4000/subscriptions',
// }));
const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('id_token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  authLink.concat(httpLink)
);
const client = new ApolloClient({
  link: link,
  cache: new InMemoryCache(),
  onError: ({ networkError, graphQLErrors }) => {
    console.log('graphQLErrors', graphQLErrors)
    console.log('networkError', networkError)
  }
});
function App() {
  return (
    <ApolloProvider client={client}>
      <div className="App" style={{overflow: "hidden", overflowY: "auto", display: "flex", height: '100vh'}}>
        <section style={{flex: '1'}} >
          <Main client={client}/>
        </section>
      </div>
    </ApolloProvider>
  );
}

export default App;
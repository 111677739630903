const getRandomPrompt = () => {
    let prompts = [
        {"prompt": "Where are you having problems?"}
        // {"prompt": "Where are you facing discrimination based on race, gender, or sexual orientation?"},
        // {"prompt": "Where are you dealing with mental health issues like depression or anxiety?"},
        // {"prompt": "Where are you struggling with addiction to substances or behaviors?"},
        // {"prompt": "Where are you enduring bullying or cyberbullying?"},
        // {"prompt": "Where are you navigating the challenges of poverty?"},
        // {"prompt": "Where are you suffering from domestic violence or abuse?"},
        // {"prompt": "Where are you managing chronic illness or disability?"},
        // {"prompt": "Where are you overcoming unemployment or underemployment?"},
        // {"prompt": "Where are you addressing homelessness or housing insecurity?"},
        // {"prompt": "Where are you coping with the impact of climate change?"},
        // {"prompt": "Where are you dealing with food insecurity or lack of access to nutritious food?"},
        // {"prompt": "Where are you navigating the challenges of immigration or refugee status?"},
        // {"prompt": "Where are you facing educational disparities or lack of access to quality education?"},
        // {"prompt": "Where are you dealing with the digital divide and lack of access to technology?"},
        // {"prompt": "Where are you experiencing age discrimination?"},
        // {"prompt": "Where are you navigating the foster care system?"},
        // {"prompt": "Where are you dealing with the social stigma of mental health?"},
        // {"prompt": "Where are you facing gender inequality?"},
        // {"prompt": "Where are you struggling with the effects of gentrification?"},
        // {"prompt": "Where are you navigating the complexities of healthcare access?"},
        // {"prompt": "Where are you addressing corruption and lack of trust in institutions?"},
        // {"prompt": "Where are you dealing with social exclusion due to physical appearance?"},
        // {"prompt": "Where are you facing barriers to LGBTQ+ rights and acceptance?"},
        // {"prompt": "Where are you struggling with parental pressure or expectations?"},
        // {"prompt": "Where are you navigating relationship issues or marital conflict?"},
        // {"prompt": "Where are you coping with the loss of a loved one?"},
        // {"prompt": "Where are you dealing with peer pressure and conformity?"},
        // {"prompt": "Where are you facing the challenges of single parenthood?"},
        // {"prompt": "Where are you overcoming language barriers?"},
        // {"prompt": "Where are you dealing with the societal impacts of aging populations?"},
        // {"prompt": "Where are you addressing the challenges of integration and multiculturalism?"},
        // {"prompt": "Where are you navigating legal system complexities or injustice?"},
        // {"prompt": "Where are you dealing with the social impacts of technological change?"},
        // {"prompt": "Where are you coping with job automation and the future of work?"},
        // {"prompt": "Where are you addressing societal expectations and norms?"},
        // {"prompt": "Where are you navigating the social impacts of globalization?"},
        // {"prompt": "Where are you dealing with conflict and unrest in communities?"},
        // {"prompt": "Where are you addressing the challenges of sustainable development?"},
        // {"prompt": "Where are you coping with the societal impacts of pandemics?"}
    ];
    let randomIndex = Math.floor(Math.random() * prompts.length);
    return prompts[randomIndex].prompt;
}


export default getRandomPrompt;
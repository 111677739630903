import { useEffect, useState } from 'react';

const SearchArea = (props) => {
    const [searchTerms, setSearchTerms] = useState([]);

    useEffect(() => {
        if (props?.search?.length > 0) {
            props.setSearchText('Search');
        } else {
            if (props.searchMade) { // Add this condition
                props.setSearchText('Clear');
            }
        }
    }, [props.search, props.searchMade]); // Add searchMade to dependency array
    
    
    return (
        <>
            <div className="flex flex-row">
                <div className="flex flex-row">
                    <form className="flex flex-row" onSubmit={(e) => { 
                        e.preventDefault();
                        if (!props.searchMade && props.search === '') return;
                        props.filterData(props.search);
                        searchTerms.push(props.search);
                    }}>
                        {searchTerms.map((term, index) => { return <span key={index} className="text-center text-white btn-active p-1 m-1 rounded-lg">{term}<div onClick={() => {
                            setSearchTerms(searchTerms.filter((terms, i) => terms !== term));
                        }} className="term-remove">x</div></span> })}
                        <input
                            id="search-value"
                            className="input-active mr-3 text-center"
                            placeholder="Search for something."
                            name="search"
                            type="text"
                            onChange={(e) => { 
                                props.setSearch(e.target.value.toLowerCase());
                                // console.log(e.target.value.toLowerCase());
                            }}
                            value={props.search}
                        >
                        </input>
                        <button className={'btn-active p-1 m-1'} type="submit">Search</button>
                        <button className={'btn-active p-1 m-1'} size="small" onClick={() => { props.filterData(''); setSearchTerms([]); props.setSearch(''); }}>Clear</button>
                        {/* <form action="https://www.paypal.com/donate" method="post" target="_top">
                            <input type="hidden" name="business" value="MGL32H29HDC7G" />
                            <input type="hidden" name="no_recurring" value="0" />
                            <input type="hidden" name="currency_code" value="USD" />
                            <input type="image" className="justify-content-center" src="https://www.paypalobjects.com/en_US/i/btn/btn_donate_SM.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
                            <img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
                        </form> */}
                    </form>
                    {/*  */}

                </div>
            </div>
        </>
    )
}

export default SearchArea;
import React, { Component, useEffect, useState } from "react";
import { useMutation, useQuery, useLazyQuery, useSubscription } from '@apollo/client';

import * as toxicity from '@tensorflow-models/toxicity';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Paper from '@mui/material/Paper';
import AddCommentIcon from '@mui/icons-material/AddComment';
import { ADD_COMMENT } from '../utils/mutations';
import { DELETE_COMMENT } from '../utils/mutations';
import { GET_COMMENTS } from '../utils/queries';
import { COMMENT_ADDED } from '../utils/subscriptions';

import Auth from '../utils/auth';
const CommentSection = (props) => {
    const [deleteComment, { error: deleteCommentError }] = useMutation(DELETE_COMMENT);

    const { comments, handleFormSubmit, handleChange, setIsLoginPaneOpen, setIsRegisterPaneOpen, currentUser } = props;

    return (
        <div className="comments-section">
            {Auth.loggedIn() ? (
                <>
                    <form onSubmit={handleFormSubmit}>
                        <textarea onChange={handleChange} name="body" className="p-2 input-active comment-textarea" placeholder="Add a comment..."></textarea>
                        <br />
                        <button className="btn-active p-1 m-1" type="submit">Submit</button>
                    </form>
                </>
            ) : (
                <p><button className="btn-active" onClick={() => setIsRegisterPaneOpen(true)}>Register</button> or <button className="btn-active" onClick={() => setIsLoginPaneOpen(true)}>Log in</button> to add a comment.</p>
            )}
            <div>
                <h2>Comments</h2>
                {comments.length === 0 ? (
                    <p>No comments yet.</p>
                ) : (
                    comments.map(comment => (
                        <div key={comment._id} id={comment._id} className="comment">
                            <p>User: {comment.userId}</p>
                            <p>{comment.body}</p>
                            <p>{comment.createdAt}</p>
                            {comment.userId === currentUser && (
                                <button className="btn-active" onClick={() => { 
                                    deleteComment({variables: {postId: props.postId, commentId: comment._id}});
                                    document.getElementById(comment._id).remove();
                                }}>Delete</button>
                            )}

                        </div>
                    ))
                )}
            </div>
        </div>
    );
};
 // update state based on form input changes
const Comments = (props) => {
    const id = props.id;
    const [comments, setComments] = useState([]);
    const { data: subscriptionData, loading: subscriptionLoading } = useSubscription(
        COMMENT_ADDED,
        { variables: { postId: props.id } } // Passing postId to the subscription
    );


    const [formState, setFormState] = useState({});
    const [isLoggedIn, setIsLoggedIn] = useState(Auth.loggedIn());

    const [addComment, { error: addCommentError }] = useMutation(ADD_COMMENT);
    // const [updatePost, { error: updatePostError }] = useMutation(UPDATE_POST);
    const [getComments, { data: commentData, loading: commentsLoading, error: commentsError }] = useLazyQuery(GET_COMMENTS);

      
    useEffect(() => {
        setIsLoggedIn(Auth.loggedIn());
    }, [Auth.loggedIn()]);
    const handleChange = (event) => {
        const { name, value } = event.target;
        // console.log(name, value);
    
        setFormState({
          ...formState,
          [name]: value,
        });
    };
    const handleAccordionChange = async (event, isExpanded) => {
        if (isExpanded) {
            getComments({ variables: { id: props.id } });
        }
    };
    const handleFormSubmit = async (event) => {
    event.preventDefault();

    try {

        const mutationResponse = addComment({
            // variables: {}
            variables: {postId: props.id, body: formState.body},
        });
        console.log(mutationResponse);
        // set to change
        console.log("Comment added!")
        // props.setIsPostPaneOpen(false);
        return await getComments({ variables: { id: props.id } })

        } catch (e) {
        console.log(e);
        }
    };

      useEffect(() => {
        if (commentData && commentData?.post?.comments) {
            console.log(commentData);
            setComments(commentData?.post?.comments);
        }
    }, [commentData, commentsLoading]);


    useEffect(() => {
        console.log(subscriptionData);
        if (subscriptionData && subscriptionData.commentAdded) {
            setComments(prevComments => [...prevComments, subscriptionData.commentAdded]);
        }
    }, [subscriptionData]);
    return (
        <Accordion onChange={handleAccordionChange} slotProps={{ transition: { unmountOnExit: true } }} key={'accordion-comments-' + id} className="rounded-xl w-full my-2 mx-auto justify-self-center text-xs">
            <AccordionSummary id="panel-header" className="mx-auto text-center" aria-controls="panel-content">
                <span key={"comment-" + id} className={"mx-auto"}>
                💬 Comments - See what others are saying.
                </span>
            </AccordionSummary>
            <AccordionDetails>
                <CommentSection 
                    comments={comments}
                    handleFormSubmit={handleFormSubmit}
                    handleChange={handleChange}
                    setIsLoginPaneOpen={props.setIsLoginPaneOpen}
                    setIsRegisterPaneOpen={props.setIsRegisterPaneOpen}
                    currentUser={props.currentUser}
                    postId={props.id}
                />
            </AccordionDetails>
        </Accordion>
    );
};

export default Comments;
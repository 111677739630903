import decode from 'jwt-decode';

class AuthService {
  signUp() {
    console.log(this);
    return decode(this.getToken());
  }

  loggedIn() {
    // Checks if there is a saved token and if it's still valid
    const token = this.getToken();
    return !!token && !this.isTokenExpired(token);
  }

  isTokenExpired(token) {
    try {
      const decoded = decode(token);
      if (decoded.exp < Date.now() / 1000) {
        return true;
      } else return false;
    } catch (err) {
      return false;
    }
  }

  getToken() {
    // Retrieves the user token from localStorage
    return localStorage.getItem('id_token');
  }

  login(idToken) {
    // Saves user token to localStorage
    localStorage.setItem('id_token', idToken);
    // window.location.assign('/');
  }

  logout() {
    // Clear user token and profile data from localStorage
    localStorage.removeItem('id_token');
        window.location.assign('/');

    // this will reload the page and reset the state of the application
  }
  // Added a method to check if the user is authenticated
  isAuthenticated() {
    return this.loggedIn();
  }

  getUserData() {
    const token = this.getToken();
    if (token) {
      try {
        // Decode the token to get user data
        const decoded = decode(token);
        console.log(decoded);
        return decoded; // Or return a specific part of the decoded data
      } catch (err) {
        console.error('Error decoding the token:', err);
        return {}; // In case of an error, return null or handle accordingly
      }
    } else {
      return {}; // If no token is found, return null or handle accordingly
    }
  }
}

// Create an instance
const authService = new AuthService(); 

export default authService;

import React, { useState, useEffect, useMemo, useRef, memo } from 'react';
import Auth from '../utils/auth';
import { Card } from 'bootstrap';
import { GET_ME } from '../utils/queries';
import { DELETE_POST, UPDATE_POST, UPVOTE_SOLUTION, DOWNVOTE_SOLUTION } from '../utils/mutations';
import { useMutation, useQuery, useLazyQuery } from '@apollo/client';
import Button from 'react-bootstrap/Button';
import Post from './Post';
import { Textfit } from 'react-textfit';
import { Tooltip } from 'react-tooltip'
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Paper from '@mui/material/Paper';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import ModeStandbyIcon from '@mui/icons-material/ModeStandby';
import { Text } from "@visx/text"
import { scaleLog } from "@visx/scale"
import Wordcloud from "@visx/wordcloud/lib/Wordcloud"
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import WarningIcon from '@mui/icons-material/Warning';
import ArticleIcon from '@mui/icons-material/Article';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import LinkIcon from '@mui/icons-material/Link';
import EmailIcon from '@mui/icons-material/Email';
import InfoIcon from '@mui/icons-material/Info';
import Comments from './Comments';
import External from './External';
import { removeStopwords } from 'stopword';
const VOTES = 'votes';
const WordcloudBuilder = memo((props) => {
    const colors = ["#000", "#333", "#555"]
    
    
    function getRotationDegree() {
      const rand = Math.random()
      const degree = rand > 0.5 ? 60 : -60
      return rand * degree
    }
    
    const fontScale = scaleLog({
        domain: [
          Math.min(...props.words.map(w => w.value)),
          Math.max(...props.words.map(w => w.value))
        ],
        range: [10, 100]
      })
      const fontSizeSetter = datum => fontScale(datum.value)
    
    const fixedValueGenerator = () => 0.5

  return (
    <div className="wordcloud">
      <Wordcloud
        key={'wordcloud-' + props.keyProp}
        words={props.words}
        width={props.width}
        height={props.height}
        fontSize={fontSizeSetter}
        font={"Impact"}
        padding={1}
        rotate={false ? getRotationDegree : 0}
        random={fixedValueGenerator}
      >
        {cloudWords =>
          cloudWords.map((w, i) => (
            <a href="#" onClick={()=> {
              alert(w.text);
            }}><Text
              key={w.text}
              fill={colors[i % colors.length]}
              textAnchor={"middle"}
              transform={`translate(${w.x}, ${w.y}) rotate(${w.rotate})`}
              fontSize={w.size}
              fontFamily={w.font}
            >
              {w.text}
            </Text></a>
          ))
        }
      </Wordcloud>
      <style>{`
        .wordcloud {
          display: flex;
          flex-direction: column;
          user-select: none;
        }
        .wordcloud svg {
          margin: 1rem 0;
          cursor: pointer;
        }

        .wordcloud label {
          display: inline-flex;
          align-items: center;
          font-size: 14px;
          margin-right: 8px;
        }
        .wordcloud textarea {
          min-height: 100px;
        }
      `}</style>
    </div>
  )
});

const UpdateInstance = (props) => {

}
const convertToHumanReadableOffices = (offices) => {
    const mapping = {
        "administrativeArea1": "Administrative Area Level 1",
        "administrativeArea2": "Administrative Area Level 2",
        "country": "Country",
        "international": "International",
        "locality": "Locality",
        "regional": "Regional",
        "special": "Special",
        "subLocality1": "Sub-Locality Level 1",
        "subLocality2": "Sub-Locality Level 2"
    };
  
    // Return the human-readable string if found, otherwise return the input string
    return mapping[offices] || offices;
  }
  function convertToHumanReadableRole(roles) {
    const roleMapping = {
        "deputyHeadOfGovernment": "Deputy Head of Government",
        "executiveCouncil": "Executive Council",
        "governmentOfficer": "Government Officer",
        "headOfGovernment": "Head of Government",
        "headOfState": "Head of State",
        "highestCourtJudge": "Highest Court Judge",
        "judge": "Judge",
        "legislatorLowerBody": "Legislator Lower Body",
        "legislatorUpperBody": "Legislator Upper Body",
        "schoolBoard": "School Board",
        "specialPurposeOfficer": "Special Purpose Officer"
    };

    // Return the human-readable string if found, otherwise return the input string
    return roleMapping[roles] || roles;
}

const IndividualSolution = (props) => {
 
  const [upVoteSolution] = useMutation(UPVOTE_SOLUTION);
  const [downVoteSolution] = useMutation(DOWNVOTE_SOLUTION);
  const [upVotedUsers, setUpVotedUsers] = useState(props.solution.upVotes);
  const [downVotedUsers, setDownVotedUsers] = useState(props.solution.downVotes);
  const [currentUserUpdated, setCurrentUserUpdated] = useState(false);
  const [upVoted, setUpVoted] = useState(false);
  const [downVoted, setDownVoted] = useState(false);
  const [rememberedSolutionNet, setRememberedSolutionNet] = useState(false);
  const storedNets = JSON.parse(localStorage.getItem(VOTES)) || {};
  const useNetValue = storedNets[props.solution._id]?.net || upVotedUsers.length - downVotedUsers.length
  const [solutionNet, setSolutionNet] = useState(useNetValue);
  const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
  function createMarkup(text) {
    const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
    const htmlText = text.replace(urlRegex, function(url) {
      return `<br/><a href="${url}" target="_blank"> ${url} </a>`;
    });
    return { __html: htmlText };
  }

  const updateLocalStorage = () => {
    const currentNets = JSON.parse(localStorage.getItem(VOTES)) || {};
    currentNets[props.solution._id] = {
      net: solutionNet,
      upVoted: upVoted,
      downVoted: downVoted
    };
    localStorage.setItem(VOTES, JSON.stringify(currentNets));
  };
  
  useEffect(() => {
    updateLocalStorage();
  }, [solutionNet, upVoted, downVoted]);
  let voteOffset = 1;
  if (solutionNet > 0) {
    voteOffset += voteOffset;
  } else if (solutionNet < 0) {
    voteOffset += voteOffset;
  }
  const handleUpVoteSolution = async (postId, solutionId) => {
    // console.log(upVotedUsers);
    // console.log(downVotedUsers)
    setDownVoted(false);
    setUpVoted(true);
    setDownVotedUsers(downVotedUsers.filter((user) => user !== props.currentUser));
    setUpVotedUsers([...upVotedUsers, props.currentUser]);
    return await upVoteSolution({ variables: { postId, solutionId } });
  };
  useEffect(() => {
    const storedNets = JSON.parse(localStorage.getItem(VOTES));
    if (storedNets && storedNets[props.solution._id]) {
      const { net, upVoted, downVoted } = storedNets[props.solution._id];
      setSolutionNet(net);
      setUpVoted(upVoted);
      setDownVoted(downVoted);
    } else {
      setSolutionNet(props.solution.upVotes.length - props.solution.downVotes.length);
    }
  }, []);

  const handleDownVoteSolution = async (postId, solutionId) => {
    console.log(downVotedUsers);
    console.log(upVotedUsers);

    setDownVoted(true);
    setUpVoted(false);
    setUpVotedUsers(upVotedUsers.filter((user) => user !== props.currentUser));
    setDownVotedUsers([...downVotedUsers, props.currentUser]);
    console.log(downVotedUsers);
    console.log(upVotedUsers);

    return await downVoteSolution({ variables: { postId, solutionId } });
  };
  useEffect(() => {
    const currentNets = JSON.parse(localStorage.getItem(VOTES)) || {};
    currentNets[props.solution._id].net = solutionNet;
    localStorage.setItem(VOTES, JSON.stringify(currentNets));
  }, [solutionNet]);

  useEffect(() => {
    if (props.solution.upVotes.includes(props.currentUser)) {
      setUpVoted(true);
    
    } else if (props.solution.downVotes.includes(props.currentUser)) {
      setDownVoted(true);
    }

  }, [props.solution.upVotes, props.solution.downVotes]);

  const colorSpan = (solutionNet) => {
    if (solutionNet > 0) {
      return <div className="vote-count text-gray-900 text-xxs">{solutionNet}</div>;
    }
    if (solutionNet < 0) {
      return <div className="vote-count text-gray-300 ">{solutionNet}</div>;
    }
    return <div className="vote-count text-gray-400 ">{solutionNet}</div>;
  }
  const useText = createMarkup(props.solution.solution);
  return (
      <div key={"solution-" + props.solution._id} className="flex shadow solution justify-end m-1">
        <div className="flex items-center justify-center vote-box">
          {Auth.loggedIn() && <button onClick={() => { if (!downVoted) { handleDownVoteSolution(props.id, props.solution._id); console.log(props.solution); setSolutionNet(solutionNet - voteOffset); setDownVoted(true); setUpVoted(false); }  }} className={"down-vote m-1"}>👇</button>}
            <div className="m-1 solutionNet">{colorSpan(solutionNet)}</div>
            {Auth.loggedIn() && <button onClick={() => {  if (!upVoted) { handleUpVoteSolution(props.id, props.solution._id); setSolutionNet(solutionNet + voteOffset); setDownVoted(false); setUpVoted(true); }}} className={"up-vote m-1"}>👆</button>}
          </div>
          <div className="solutionText  flex justify-between items-center p-1 m-1  mx-auto text-md" dangerouslySetInnerHTML={useText}></div>

          <hr/>
          
      </div>  
  );
}


const Solutions = (props) => {
 
    let solutions = props.solutions;

    try {
      solutions = JSON.parse(props.solutions);
    } catch(e) {
      solutions = props.solutions;
    }
    if (solutions?.length > 0) {
      return (
          <div className="text-xs">
              {solutions && solutions.map((solution, index) => {
                return <IndividualSolution solution={solution} key={props.id} id={props.id}/>                 
              })}
          </div>
      );
    } else {
      // console.log(solutions);
      // console.log("No solutions found.");
    }
}

const Issues = (props) => {
    const issues = props.issues;
    // console.log(issues);

    if (issues?.length > 0) {
      return (
        <Accordion slotProps={{ transition: { unmountOnExit: true } }} key={'accordion-issues-' + props.id} className="rounded-xl w-100 my-2 mx-auto justify-self-center text-xs">
        <AccordionSummary id="panel-header" className="mx-auto text-center" aria-controls="panel-content">
            <p key={"issues-" + props.id} className={"mx-auto"}>
            ❗ Related Issues - View issue categories.</p>
            <p></p>
        </AccordionSummary>
              {issues.map((issue, index) => {
                  return (
                
                    <AccordionDetails>
                      <div key={"issue-" + props.id + '-' + index} className=" align-items-center justify-content-center text-center text-xs">
                          <div><button className="btn-active mx-1" onClick={() => { 
                            props.filterData(issue.category);
                            props.setSearchText(issue.category);
                            props.setSearch(issue.category); 
                            props.setIsSelectedPaneOpen(false);
                          }}>{issue.category}</button>
                          {issue.issues.map((issue, index) => {
                              return (
                                  <button onClick={() => {
                                    props.filterData(issue);
                                    props.setSearchText(issue.toLowerCase());
                                    props.setSearch(issue); 
                                    props.setIsSelectedPaneOpen(false);
                                    props.setViewport({zoom: 1});
                                  }} key={"issue-" + index} style={{color: "#777"}} className="btn-active mx-1 mx-auto">
                                        {issue}
                                    </button>
                              )}
                          )}
                          </div>
                      </div>  
                    </AccordionDetails>
                
                  );
              })}
          </Accordion>
      );
    } else {
      // console.log(issues);
      // console.log("No issues found.");
    }
}

const PostInstance = memo((props) => {
    // console.log(props.post);
    const [isVisible, setIsVisible] = useState(false);
    const [firstLoad, setFirstLoad] = useState(true);
    const postRef = useRef(null);
    const buildSolutionEmail = (solutions) => {
        let email = "\n\nI would like to propose the following solution(s) to address the issue(s) above:\n\n";
        try {
          solutions.forEach((solution) => {
              email += solution.solution + "\n\n";
          });
        } catch(e) {
        }
        email += "\n\nThank you for your time and consideration.\n\nSincerely,\n\n";
        console.log(email);
        return email;
    };

    function scaleFontSize(textLength) {
        // Clamp the length of the text between 0 and 128
        const clampedLength = Math.min(Math.max(textLength, 0), 128);
      
        // Calculate the font size based on the length of the text.
        // The formula used for scaling linearly is:
        // (y2 - y1) / (x2 - x1) * (x - x1) + y1
        // where (x1, y1) and (x2, y2) are the points (0, 72) and (128, 18)
        // representing the maximum and minimum font sizes at the corresponding text lengths.
        const fontSize = ((18 - 48) / 128) * clampedLength + 48;
      
        return `${fontSize}px`;
    }

    const getZoomLevel = () => {
        try {
            if (props.viewport.zoom > 12.5) { 
                return props.viewport?.zoom;
            } else {
                return 12.5;
            }
        } catch (e) {
            return 12.5;
        }
    }
    const buildLocation = (civicInfo) => {
        if (civicInfo?.normalizedInput ?? false) {
            return (
                <div>{civicInfo?.normalizedInput?.city ?? ''}, {civicInfo?.normalizedInput?.state ?? ''}, {civicInfo?.normalizedInput?.zip ?? ''}</div>
            );
        }
    };
    const buildCivicInfo = (civicInfo, body, solution) => {
        if (civicInfo === "No civic info available." || civicInfo === null) {
            return (
                <div>No civic information available yet.</div>
            );
        }
        let officeCounter = 0;
        return civicInfo?.offices?.reverse().map((office) => {
            officeCounter++;
            const officials = office.officialIndices.map((index) => civicInfo.officials[index]);
            // console.log(officials);
            return (
                                            
                <div key={"paper-" + officeCounter} elevation={1} className={"my-1"}>
                  <div key={`office-${officeCounter}`} className={"py-2"}>
                      <div>{office.name}</div>
                      {/* <div style={{fontSize: "9px"}}>{convertToHumanReadableRole(office.roles[0])}</div> */}
                      {/* Display each official's name (or any other property) for this office */}
                      {officials.map((official, index) => (
                          <div key={`official-${index}`}>
                              <div>
                                <div className={"my-1"}>
                                {official.name}
                                </div>
                                <br/>
                                <a className="hotlink" href={official?.urls?.[0] ?? '#'} target="_blank">
                                    <LinkIcon/> Website
                                </a>
                                <br/>
                                {official?.urls?.[1]?.includes('wiki') &&<><a className="hotlink" href={official?.urls?.[1] ?? '#'} target="_blank">
                                    <HelpCenterIcon /> Wikipedia
                                </a><br/></>}
                                <a className="hotlink" href={"https://www.google.com/search?q=" + encodeURI(official.name) + "+controversy"} target="_blank">
                                    <WarningIcon/> Controversy
                                 </a>
                                 <br/>
                                  {official.emails && <><a className="hotlink" href={"mailto:" + official.emails[0] + "?subject=" + body + "&body=" + encodeURIComponent(body + "\n" + buildSolutionEmail(solutions))}><EmailIcon/> Email
                                </a><br/></>}
                              </div>
                              
                             
                          </div>
                          
                      ))}
                  </div>
                  {officeCounter < civicInfo.offices.length ? <hr/> : null}
                </div>
            );
        });
    };

    const [deletePost, { error, data }] = useMutation(DELETE_POST);
    const postData = props.post;
    const id = postData.properties.id;
    const user = postData.properties.user;
    const body = postData.properties.body;
    const solutions = postData.properties.solutions;
    let issues = [];
    try {
      issues = JSON.parse(postData.properties.issues);
    } catch(e) {
      issues = postData.properties.issues;
    }
    let civicInfoObject = postData?.properties?.civicInfo;
    if (typeof civicInfoObject === 'string') {
        try {
            // Attempt to parse the string to an object
            civicInfoObject = JSON.parse(civicInfoObject);
        } catch (error) {
            // If parsing fails, log the error and set a default message
            // console.error("Failed to parse civic info:", error);
            civicInfoObject = "No civic info available.";
        }
    }
    
    // Ensure buildCivicInfo is called with the correctly parsed object or the default message
    let civicInfoData;
    if (civicInfoObject !== "No civic info available.") {
        // If parsing was successful, use the parsed object
        civicInfoData = buildCivicInfo(civicInfoObject, body, solutions);
    } else {
        // Handle the case where parsing was unsuccessful or not needed
        // Adjust this part according to how you want to handle the default/fallback scenario
        civicInfoData = civicInfoObject; // or any other default processing
    }
    const time = new Date(postData.properties.time).toLocaleString();
    const nearby = postData.properties.nearby;
    const latitude = postData.geometry.coordinates[1].toFixed(5);
    const longitude = postData.geometry.coordinates[0].toFixed(5);

    useEffect(() => {
      const observer = new IntersectionObserver(
          (entries) => {
              // The callback will return an array of entries, even if you are observing a single item
              const [entry] = entries;
              // Use the isIntersecting property to determine if the element is in the viewport
              if (entry.isIntersecting) {
                  setIsVisible(true);
                  observer.disconnect(); // Optional: disconnect the observer if you only want to load once
              }
          },
          {
              rootMargin: '100px', // Adjust rootMargin to load items slightly before they come into view
          }
      );

      const currentPostRef = postRef.current;
      if (currentPostRef) {
          observer.observe(currentPostRef);
      }

      return () => {
          if (currentPostRef) {
              observer.unobserve(currentPostRef);
          }
      };
    }, []);
    if (!isVisible) {
      
      // if (firstLoad && !isVisible) {
      //   setFirstLoad(false);
      //   return <div ref={postRef} className={"post drop-shadow-xl rounded-xl align-items-center justify-content-center text-center"}>Loading...</div>;
      // }
    }
    return (
    <Paper elevation={5} className="rounded-2xl text-lg">
      <div id={id} key={id} className={"post rounded-3xl  align-items-center justify-content-center text-center"}>
          <div width="100%" height="100%" id={id + "-text"}>
          <div className="text-sm break-words">
              <div elevation={2} className="p-1 rounded-3xl  text-lg">
                  {body}
                  <h1 className="m-2 text-sm">by: {user}<br/></h1>
              
              <div className="post-byline m-2">Latitude, Longitude: {latitude}, {longitude}
              <br/>
              {buildLocation(civicInfoObject)}
              {time}
              {nearby && <><br/><a
                  data-tooltip-id="nearby-tooltip"
                  data-tooltip-content="Nearby when posting."
                  data-tooltip-place="top"
                > <span className="material-symbols-outlined">
                how_to_reg
                </span></a><Tooltip id="nearby-tooltip" /></>}
                <br/>
                <span style={{fontSize: "10px"}} className="text-left">ID: {id}</span>
                <br/>
                </div>
                <button className="btn-active m-3" onClick={() => {
                  props.setViewport({latitude: postData.geometry.coordinates[1], longitude: postData.geometry.coordinates[0], zoom: getZoomLevel()});
                  props.setIsSelectedPaneOpen(false);
                  props.setIsVisiblePaneOpen(false);
                }}>🔍 View</button>
                {(props?.currentUser?.toString() === postData.properties.userId.toString()) && 
                    <>
                        <Button className="btn-active m-3" onClick={() => { 
                            props.setEditUser(postData.properties.userId.toString());
                            props.setEditPostId(id); 
                            props.setEditPostBody(body); 
                            props.setIsEditMode(true);
                            props.setIsPostPaneOpen(true);
                            props.setIsSelectedPaneOpen(false);
                        }} type="button">📝 Update</Button>
                        <Button className="btn-active m-3" onClick={() => {
                            deletePost({variables: { userId: postData.properties.userId, postId: id}});
                            document.getElementById(id).remove();
                        }} type="button">☠️ Delete</Button>
                      </>
                    
               }
               </div>
              <Issues 
                id={id}
                currentUser={props.currentUser}
                filterData={props.filterData}
                setSearch={props.setSearch}
                searchText={props.searchText}
                setSearchText={props.setSearchText}
                search={props.search}
                searchMade={props.searchMade}
                setSearchMade={props.setSearchMade}
                setIsSelectedPaneOpen={props.setIsSelectedPaneOpen}
                setViewport={props.setViewport}
                issues={issues} 
              />
              
              <Accordion slotProps={{ transition: { unmountOnExit: true } }} key={'accordion-solutions-' + id} className="rounded-xl w-100 my-2 mx-auto justify-self-center text-xs">
                    <AccordionSummary id="panel-header" className="mx-auto text-center" aria-controls="panel-content">
                        <span key={"government-" + id} className={"mx-auto"}>✨ Generated Solutions - AI generated solutions.</span>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Solutions currentUser={props.currentUser} id={id} solutions={solutions} /> 
                    </AccordionDetails>
                </Accordion>
                <Accordion slotProps={{ transition: { unmountOnExit: true } }} key={'accordion-civic-' + id} className="rounded-xl w-100 my-2 mx-auto justify-self-center text-xs">
                    <AccordionSummary id="panel-header" className="mx-auto text-center" aria-controls="panel-content">
                        <p key={"government-" + id} className={"mx-auto"}>
                        🗳️ Government Representatives - View the government representatives in this area.</p>
                        <p></p>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="representative-window">
                        <i>Please be respectful.</i>
                        {civicInfoData}
                      </div>
                    </AccordionDetails>
                </Accordion>
                <Comments 
                    currentUser={props.currentUser}
                    id={id}
                    setIsLoginPaneOpen={props.setIsLoginPaneOpen}
                    setIsRegisterPaneOpen={props.setIsRegisterPaneOpen}
                />
                <External
                  id={id}
                  latitude={latitude}
                  longitude={longitude}
                />
          </div>
          </div>
      </div>
    </Paper>)
});
const SelectedPosts = memo((props) => {
    const [combinedText, setCombinedText] = useState('');
    const [wordsForCloud, setWordsForCloud] = useState([]);
    const [solutionWordsForCloud, setSolutionWordsForCloud] = useState([]);
    const [selectedText, setSelectedText] = useState();
    const [postResults, setPostResults] = useState();
    const [selectedPosts, setSelectedPosts] = useState();
    const [showWordCloud, setShowWordCloud] = useState(false);

    function wordFreq(text) {

        const words = text.replace(/\./g, "").split(/\s/)
        const filteredWords = removeStopwords(words);

        const freqMap = {}
      
        for (const w of filteredWords) {
          if (!freqMap[w]) freqMap[w] = 0
          freqMap[w] += 1
        }
        return Object.keys(freqMap).map(word => ({
          text: word,
          value: freqMap[word]
        }))
      }
    // props.setIsSelectedPostPaneOpen(true);
    // console.log(props.selectedMapPosts);
    const selectedPostLength = props.selectedMapPosts.length;
    let selectedPostText = "Posts"
    if (selectedPostLength === 1) {
      selectedPostText = "Post";
    } 
    useEffect(() => {
        // console.log(props);
        // Post index.
        let keyCount = 0;
        if (props.selectedMapPosts.length > 0) {
            // console.log(props.selectedMapPosts);
            //sort((a, b) => {
                // Sort by 'nearby' first: true values come before false
            //     if (a.properties.nearby === b.properties.nearby) {
            //         // If 'nearby' is the same, sort by 'createdAt'
            //         return a.properties.createdAt - b.properties.createdAt;
            //     }
            //     return a.properties.nearby ? -1 : 1;
            // }).slice(0, 50).
            let allText = '';
            let solutionText = '';
            // console.log(props.selectedMapPosts);
            setSelectedPosts(props.selectedMapPosts.map((post) => {
                allText += `${post.properties.body} `;
                for (let i = 0; i < post.properties.solutions?.length; i++) {
                    solutionText += `${post.properties.solutions[i].solution} `;
                }
                solutionText += `${post.properties.solution} `;
                return <PostInstance
                        key={`post-${post.properties.id}`}
                        post={post}
                        filterData={props.filterData}
                        setSearch={props.setSearch}
                        searchText={props.searchText}
                        setSearchText={props.setSearchText}
                        search={props.search}
                        searchMade={props.searchMade}
                        setSearchMade={props.setSearchMade}
                        setEditUser={props.setEditUser}
                        setEditPostId={props.setEditPostId}
                        setEditPostBody={props.setEditPostBody}
                        setIsVisiblePaneOpen={props.setIsVisiblePaneOpen}
                        setIsEditMode={props.setIsEditMode}
                        setIsPostPaneOpen={props.setIsPostPaneOpen}
                        setIsLoginPaneOpen={props.setIsLoginPaneOpen}
                        setIsRegisterPaneOpen={props.setIsRegisterPaneOpen}
                        setIsSelectedPaneOpen={props.setIsSelectedPaneOpen}
                        setViewport={props.setViewport}
                        viewport={props.viewport}
                        currentUser={props.currentUser}
                    />;
            }));
            setCombinedText(allText.trim());
            
            setSolutionWordsForCloud(wordFreq(solutionText.trim()));
            setWordsForCloud(wordFreq(allText));
        } else {
            if (props.selectedMapPosts.length === 0) {
                props.setIsPostPaneOpen(false);
            }
            setSelectedPosts(<PostInstance 
                    key={`zero-post-${props.post.properties.id}`} 
                    post={props.selectedMapPosts[0]}
                    filterData={props.filterData}
                    setSearch={props.setSearch}
                    searchText={props.searchText}
                    setSearchText={props.setSearchText}
                    search={props.search}
                    searchMade={props.searchMade}
                    setSearchMade={props.setSearchMade}
                    setEditUser={props.setEditUser}
                    setEditPostId={props.setEditPostId}
                    setEditPostBody={props.setEditPostBody}
                    setIsLoginPaneOpen={props.setIsLoginPaneOpen}
                    setIsRegisterPaneOpen={props.setIsRegisterPaneOpen}
                    setIsEditMode={props.setIsEditMode}
                    setIsPostPaneOpen={props.setIsPostPaneOpen}
                    setIsSelectedPaneOpen={props.setIsSelectedPaneOpen}
                    setViewport={props.setViewport}
                    currentUser={props.currentUser}
                />);
        }
        // props.setIsSelectedPaneOpen(true);
    }, [props.selectedMapPosts, props.mainPostData])
    return (
        <>
        <div className="flex justify-center text-4xl m-4">
          {selectedPostLength + " " + selectedPostText}
          </div>
          <button className="w-25 btn-active" onClick={() => {
              setShowWordCloud(!showWordCloud);
            }
            }>🔍 Show Word Cloud</button>
        <div id="issue-cloud" className="flex flex-col items-center mt-2">
          <div className="m-1">
            
            <br/>
            { showWordCloud &&
            (<>
              <span className="p-1 text-xl flex justify-center">Word Clouds</span>
              <WordcloudBuilder key="issue-cloud" keyProp="issue-cloud" width={400} height={400} words={wordsForCloud} />
              <WordcloudBuilder key="solution-cloud"  keyProp="solution-cloud" width={400} height={400} words={solutionWordsForCloud} />
            </>
          )}
          </div>
        </div>
        <div key={"selected-posts-" + props.selectedMapPosts.length} id="posts">
          {selectedPosts}
        </div>
       <hr/>
      </>
    );
});

export default SelectedPosts;